import { QueryOptions } from '@apollo/client/core';
import { dataLoader, type RedisDataLoaderOptions } from '@root/libs/redis/dataloader/dataLoader';

import Service from '@root/common/base/Service';

import { CategoryFetchState, CategoryFetchContext } from '@root/modules/category/domain';

import getCategoriesWithFeed from '@root/modules/category/graphql/getCategoriesWithFeed.graphql';

import type { ServiceResponse } from '@root/common/types/service';
import type { GetCategoriesWithFeedApiResponse } from '@root/modules/category/types/categories';

interface CategoryWithFeedServiceVariables {
  id: number;
  domain: string;
  authorLanguage: string;
}

export class CategoryWithFeedService extends Service {
  /**
   * Fetch category with feed data either from redis or from API
   * Store data with redisDataLoader if data is fetched from API
   */
  public async fetch(state: CategoryFetchState, context: CategoryFetchContext): ServiceResponse<GetCategoriesWithFeedApiResponse> {
    const variables: CategoryWithFeedServiceVariables = {
      id: Number(context.route.params.id),
      domain: context.channel.settings.domain,
      authorLanguage: context.channel.settings.lang.toUpperCase(),
    };

    const options: QueryOptions<CategoryWithFeedServiceVariables> = Object.assign({ query: getCategoriesWithFeed }, { variables });
    const dataLoaderOptions: RedisDataLoaderOptions = {
      remote: {
        expireTimeMs: 30000, // 30 seconds
        gracePeriodMs: 24 * 3600 * 1000, // 24 hours
        keyPrefix: 'category_feed',
      },
      local: {
        cache: false,
      },
    };

    // Create a request wrapper to handle API requests
    const requestWrapper = async (options: QueryOptions<CategoryWithFeedServiceVariables>): Promise<GetCategoriesWithFeedApiResponse | Error> => {
      const apiProvider = this.createProvider('GraphQL');
      apiProvider.selectAPI('content-api-v3').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });
      const response = await apiProvider.query<GetCategoriesWithFeedApiResponse>(options);

      this.throwGraphqlOrApolloErrorIfExists(response);

      return response.data;
    };

    const redisDataLoader = dataLoader<QueryOptions<CategoryWithFeedServiceVariables>, GetCategoriesWithFeedApiResponse | Error>(
      requestWrapper,
      dataLoaderOptions
    );

    // Fetch data from redis or API
    const response: GetCategoriesWithFeedApiResponse | Error = redisDataLoader
      ? await redisDataLoader.load(options)
      : await this.requestWrapperHandler<GetCategoriesWithFeedApiResponse | Error>(() => requestWrapper(options));

    // Handle internal graphql errors
    if (response instanceof Error) {
      const errorData = this.generateErrorData(response);
      return [null, errorData];
    }

    return [response, null];
  }
}
