import { Route } from 'vue-router';
import { GetChannelConfigFunction } from '@root/libs/init-channel/types';
import { Data as CategoriesData } from '@root/modules/category/types/categories';

export default function getCategoryAdsSettings({
  $channelConfig,
  route,
  category,
}: {
  $channelConfig: GetChannelConfigFunction;
  route: Route;
  category: CategoriesData;
}) {
  const { id } = $channelConfig('settings');

  // Create keywords data
  const keywords = [`${id}_${route.params.slug}`, `category_${category.id}`];

  // Create keyValues data
  const parentCategoryKey = category.parentCategory ? `_${category.parentCategory?.slug}` : '';

  const keyValue = {
    category: `${id}${parentCategoryKey}_${route.params.slug}`,
  };

  // Get page placements
  const { component } = $channelConfig('page').category;
  const adsConfig = component?.adForm?.filter((ad) => ad.categories.includes(route.params.id)) || [];
  const placements = adsConfig[0]?.placements ? adsConfig[0]?.placements : {};

  // Get enabled ads
  const enableAds = adsConfig[0]?.subscriberShow ? adsConfig[0]?.subscriberShow : [];

  return {
    mkw: keywords,
    mkv: keyValue,
    placements,
    enableAds,
  };
}
