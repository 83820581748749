import { Data as HeadlinesData } from '@root/modules/headlines/types/headlines';
import type { Feed } from '@root/modules/feed/types';

type Settings =
  | {
      icons: boolean;
      lead: boolean;
      titleComment: boolean;
      relatedTitles: boolean;
      author: boolean;
      channel: boolean;
      category: boolean;
    }
  | Record<string, unknown>;

export default function convertHeadlinesToFeed(settings: Settings, headlines: HeadlinesData[]) {
  const feed = [];
  // BlockType7 takes maximum of 12 headlines
  const stepAmount = Math.ceil(headlines.length / 12);

  for (let step = 0; step < stepAmount; step++) {
    const isLast = step === stepAmount - 1;

    feed.push({
      attributes: {
        settings: {
          bannerPlacement: isLast ? 'side' : 'bottom_and_side',
        },
      },
      targeting: {
        userType: null,
        screenSize: null,
      },
      blocks: [
        {
          attributes: {
            settings,
          },
          type: 'BlockType7',
          data: {
            items: headlines.splice(0, 12),
          },
        },
      ],
    });
  }
  // TODO (types): Fix headline and feed incompatible types
  return feed as unknown as Feed;
}
