import { FetchState } from '@root/common/domain';
import type { FetchState as IFetchState, FetchStateInput } from '@root/common/types/domain';
import type { Data as Category } from '@root/modules/category/types/categories';
import type { Feed } from '@root/modules/feed/types';

interface ICategoryFetchState extends IFetchState {
  category: Category | null;
  feed: Feed | null;
}

export class CategoryFetchState extends FetchState implements ICategoryFetchState {
  private _category: ICategoryFetchState['category'];
  private _feed: ICategoryFetchState['feed'];

  constructor(input: FetchStateInput<ICategoryFetchState>) {
    super(input);
    this._category = input.category;
    this._feed = input.feed;
  }

  get category(): ICategoryFetchState['category'] {
    return this._category;
  }

  set category(category: ICategoryFetchState['category']) {
    this._category = category;
  }

  get feed(): ICategoryFetchState['feed'] {
    return this._feed;
  }

  set feed(feed: ICategoryFetchState['feed']) {
    this._feed = feed;
  }
}
