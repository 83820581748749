
import Page from '@root/common/base/Page';

import { CategoryFetchState, CategoryFetchContext, CategoryFetchHandler, CategoryPath, CategoryRedirectStatus } from '@root/modules/category/domain';
import { CategoryWithHeadlinesService, CategoryWithFeedService } from '@root/modules/category/services';

import config from '@root/modules/category/config/category.config';

import GridSkeletonLoader from '@root/common/components/base/loader/GridSkeletonLoader.vue';
import FeedParser from '@root/common/components/helpers/FeedParser.vue';
import Pagination from '@root/common/components/base/pagination/Pagination.vue';
import CategoryInfo from '@root/modules/category/components/CategoryInfo.vue';

import buildPictureUrl from '@core/utils/buildPictureUrl';
import getHeadlineWithHref from '@root/modules/headlines/utils/getHeadlineWithHref';
import convertHeadlinesToFeed from '@root/modules/category/utils/convertHeadlinesToFeed';
import getCategoryAdsSettings from '@root/modules/category/utils/getCategoryAdsSettings';

import type { Feed } from '@root/modules/feed/types';
import type { FetchHeadlines, QueryResult } from '@root/modules/headlines/types/headlines';
import type { Data as CategoryData } from '@root/modules/category/types/categories';
import { RefetchType } from '@root/common/types/domain';

interface Data {
  configCategories: string[];
  feed: Feed;
  pager: QueryResult['pager'];
  category: CategoryData | null;
  categoryInfoVisible: boolean;
}

interface Methods {
  changePage: () => void;
  handleRedirect: (category: CategoryData) => void;
  setCategoryInfoVisibility: () => void;
  buildFeed: (headlines: FetchHeadlines['data']) => void;
}

export default Page.extend<Data, Methods, unknown, unknown>({
  components: {
    CategoryInfo,
    GridSkeletonLoader,
    FeedParser,
    Pagination,
  },
  data() {
    return {
      configCategories: [],
      feed: [],
      pager: {
        count: 0,
        limit: 0,
        offset: 0,
      },
      category: null,
      categoryInfoVisible: false,
    };
  },
  beforeRouteUpdate(to, from, next) {
    const reset = !this.configCategories.includes(String(to.params.id));

    // Check if next category uses current bottom navigation
    // if it does not then reset navigation
    if (reset) {
      this.resetPageHeaderBottomNavigation();
    }

    this.resetPageAdsSettings();

    next();
  },
  beforeRouteLeave(to, from, next) {
    this.resetPageAdsSettings();
    this.resetPageHeaderBottomNavigation();

    next();
  },
  watch: {
    'category.id'(id: number) {
      if (id) {
        // Get category header data from configuration api
        const { component } = this.$channelConfig('page').category;
        const config = component?.header?.filter((navigation: { categories: string | string[] }) => navigation.categories.includes(String(id))) || [];

        this.configCategories = config[0]?.categories || [];

        this.setPageHeaderBottomNavigation(config[0]?.navigation?.bottom);
        this.setCategoryInfoVisibility();
        this.fixPageUrlParams('category', { id, slug: this.category?.slug });
      }
    },
  },
  async fetch() {
    const categoryId = Number(this.$route.params.id);
    const pageBuilderIds = this.$channelConfig('config').page.category.settings?.pageBuilderIds || [];
    const isPageBuilderEnabled = pageBuilderIds.includes(categoryId);

    const categoryFetchState = new CategoryFetchState({ refetchType: this.refetch.activeType, category: this.category, feed: this.feed });
    const categoryFetchContext = new CategoryFetchContext({ channel: this.$channelConfig('config'), route: this.$route });

    const categoryFetchHandler = new CategoryFetchHandler(categoryFetchState, categoryFetchContext);

    // Fetch category data
    // Fetch feed data if page builder is enabled for category or fetch regular headlines
    this.startFetching(categoryFetchHandler.state.fetchStatus);
    const categoryDataService = isPageBuilderEnabled ? new CategoryWithFeedService() : new CategoryWithHeadlinesService();
    const [categoryData, categoryError] = await categoryFetchHandler.handleFetch(categoryDataService);

    if (categoryError) {
      // Try 1 refetch before rendering error page
      const willRefetch = this.handleFetchError(categoryFetchHandler.state.fetchStatus, categoryError);

      if (willRefetch) {
        return;
      }

      this.handlePageError(categoryError);
      return;
    }

    const { category, feed, headlines } = categoryData;

    this.handleRedirect(category);

    // Set ads settings before category data is updated to make sure that ads are displayed correctly
    const categoryAds = getCategoryAdsSettings({ $channelConfig: this.$channelConfig, route: this.$route, category });
    this.setPageAdsSettings(categoryAds);

    this.$store.commit('category/setCategoryData', category);

    // Set category for rendering and fetch feed data or build feed out ouf headlines
    this.category = category;

    // Use feed if it was fetched with channel config id
    if (feed) {
      this.feed = feed;
    }

    // Build feed out of headlines if feed was not fetched
    if (headlines) {
      this.buildFeed(headlines);
    }

    // Handle successful fetch
    this.stopFetching(categoryFetchHandler.state.fetchStatus);
  },
  head() {
    const meta = this.getPageMeta();
    const cXenseMeta = [];

    if (this.category) {
      const { name, attributes } = this.category;

      meta.setTitle({ title: attributes.title || name });

      if (attributes.description) {
        meta.description = attributes.description;
        meta.ogDescription = attributes.description;
      }
      // Sometimes we are returned an array sometimes a string
      if (attributes.keywords) {
        if (Array.isArray(attributes.keywords)) {
          meta.keywords = attributes.keywords.join();
        } else {
          meta.keywords = attributes.keywords;
        }
      }

      if (attributes.image) {
        meta.imageData = { width: 1200, height: 800, src: buildPictureUrl({ id: attributes.image.id, cropperData: attributes.image.attributes.cropperData }) };
      }

      cXenseMeta.push({ hid: 'cXenseParse:eks-article-section-id', name: 'cXenseParse:eks-article-section-id', content: String(this.category.id) });
    }

    return {
      title: meta.title,
      meta: [...meta.data, ...cXenseMeta],
      link: meta.link,
    };
  },
  methods: {
    changePage() {
      this.addRefetchToQuery(RefetchType.Force);
    },
    setCategoryInfoVisibility() {
      const bottomNavigationType = this.$store.state.navigation.header.data.bottom.settings?.type;

      this.categoryInfoVisible = !!(this.category && !config.hideCategoryInfoOnNavigationType.includes(bottomNavigationType));
    },
    buildFeed(headlines: FetchHeadlines['data']) {
      const { component } = this.$channelConfig('page').category;
      const { domain, locale } = this.$channelConfig('settings');
      const fakeDomainEnabled = this.$config.FAKE_DOMAIN_ENABLED;
      // Convert headlines to headlines with valid href
      const headlinesWithHref = headlines.items.map((headline) => {
        return getHeadlineWithHref({ headline, domain, locale, fakeDomainEnabled });
      });

      const settings = component?.BlockType7 || {};
      const feed = headlinesWithHref ? convertHeadlinesToFeed(settings, headlinesWithHref) : [];

      this.feed = feed;
      this.pager = headlines.pager;
    },
    handleRedirect(category: CategoryData) {
      const categoryPath = new CategoryPath(category);

      if (!categoryPath.getState('isDataValid')) {
        this.$sentry.captureException(categoryPath.getState('error'), {
          tags: { 'process.type': process.server ? 'server' : 'client' },
        });
        return;
      }

      const redirectPath = categoryPath.createPath({
        domain: this.$channelConfig('settings').domain,
        fakeDomainEnabled: this.$config.FAKE_DOMAIN_ENABLED,
        query: this.$nuxt.context.query,
        route: this.$nuxt.context.route,
      });

      if (categoryPath.getState('redirectStatus') === CategoryRedirectStatus.Error) {
        this.$sentry.captureException(categoryPath.getState('error'), {
          tags: { 'process.type': process.server ? 'server' : 'client' },
        });
        return;
      }

      if (categoryPath.getState('redirectStatus') === CategoryRedirectStatus.Redirect) {
        this.handlePageRedirect(redirectPath);
      }
    },
  },
});
