import { RootContext } from '@root/common/domain';
import type { RootContextInput } from '@root/common/types/domain';

type CategoryExposeContext = 'channel' | 'route';

export class CategoryFetchContext extends RootContext<CategoryExposeContext> {
  constructor(input: RootContextInput<CategoryExposeContext>) {
    super(input);
  }
}
