
import Vue from 'vue';
import Dropdown from '@core/components/UI/dropdown/Dropdown.vue';
import DropdownItem from '@core/components/UI/dropdown/DropdownItem.vue';
import { GetCategoriesWithHeadlines } from '@root/modules/category/types/categories';
import { CategoryBase } from '@root/common/types/fragment';
import { buildRelativeRoute } from '@root/router/utils/buildRoutePath';

interface Methods {
  categoryUrl: (category: CategoryBase) => string;
}

interface Props {
  category: GetCategoriesWithHeadlines['data']['categories']['data'][0];
}

interface Computed {
  subCategories: CategoryBase[];
  firstPart: CategoryBase[];
  secondPart: CategoryBase[];
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    Dropdown,
    DropdownItem,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  computed: {
    subCategories() {
      return this.category.categories?.items || [];
    },
    firstPart() {
      return this.subCategories.length > 0 ? this.category.categories.items.slice(0, 5) : [];
    },
    secondPart() {
      return this.subCategories.length > 5 ? this.category.categories.items.slice(5) : [];
    },
  },
  methods: {
    categoryUrl(category) {
      const pathOptions = {
        type: 'category',
        id: category.id,
        slug: category.slug,
        channel: category.channel,
      };

      return buildRelativeRoute({ pathOptions });
    },
  },
});
