import { QueryOptions } from '@apollo/client/core';
import { dataLoader, RedisDataLoaderOptions } from '@root/libs/redis/dataloader/dataLoader';

import Service from '@root/common/base/Service';

import { CategoryFetchState, CategoryFetchContext } from '@root/modules/category/domain';

import getCategoriesWithHeadlines from '@root/modules/category/graphql/getCategoriesWithHeadlines.graphql';

import { getRouteParams } from '@root/common/utils/url';
import { getPageOffset } from '@root/common/utils/getPageOffset';

import type { ServiceResponse } from '@root/common/types/service';
import type { GetCategoriesWithHeadlines } from '@root/modules/category/types/categories';

type CategoryWithHeadlinesFetchVariables = GetCategoriesWithHeadlines['variables'];
type CategoryWithHeadlinesApiResponse = GetCategoriesWithHeadlines['data'];

export class CategoryWithHeadlinesService extends Service {
  constructor(variables?: { req?: PortalRootIncomingMessage }) {
    super({ req: variables?.req, serviceType: 'category' });
  }

  /**
   * Get the service variables required for making a request
   */
  private getServiceVariables(state: CategoryFetchState, context: CategoryFetchContext): CategoryWithHeadlinesFetchVariables {
    const params = getRouteParams({ id: context.route.params.id, slug: context.route.params.slug, desiredIdType: 'number' });
    const limit = 41;
    const page = context.route.query.page as string;
    const offset = getPageOffset(limit, page);

    const variables: CategoryWithHeadlinesFetchVariables = {
      offset,
      limit,
      id: params.id,
      slug: params.slug,
      domain: context.channel.settings.domain,
      authorLanguage: context.channel.settings.lang.toUpperCase(),
    };

    return variables;
  }

  /**
   * Fetch category with headlines data either from redis or from API
   * Store data with redisDataLoader if data is fetched from API
   */
  public async fetch(state: CategoryFetchState, context: CategoryFetchContext): ServiceResponse<CategoryWithHeadlinesApiResponse> {
    const variables = this.getServiceVariables(state, context);
    const options = Object.assign({ query: getCategoriesWithHeadlines }, { variables });
    const dataLoaderOptions: RedisDataLoaderOptions = {
      remote: {
        keyPrefix: 'category',
      },
    };

    // Create a request wrapper to handle API requests
    const requestWrapper = async (options: QueryOptions): Promise<CategoryWithHeadlinesApiResponse | Error> => {
      const apiProvider = this.createProvider('GraphQL');
      apiProvider.selectAPI('content-api-v3').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });
      const response = await apiProvider.query<CategoryWithHeadlinesApiResponse>(options);

      this.throwGraphqlOrApolloErrorIfExists(response);

      return response.data;
    };

    if (variables.offset !== 0) {
      dataLoaderOptions.remote!.gracePeriodMs = 600000; // 10 minutes for pager
    }

    const redisDataLoader = dataLoader<QueryOptions, CategoryWithHeadlinesApiResponse | Error>(requestWrapper, dataLoaderOptions);

    // Fetch data from redis or API
    const response: CategoryWithHeadlinesApiResponse | Error = redisDataLoader
      ? await redisDataLoader.load(options)
      : await this.requestWrapperHandler<CategoryWithHeadlinesApiResponse | Error>(() => requestWrapper(options));

    // Handle internal graphql errors
    if (response instanceof Error) {
      const errorData = this.generateErrorData(response);
      return [null, errorData];
    }

    return [response, null];
  }
}
