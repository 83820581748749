import { PagePathHandler, RedirectStatus, RedirectConditions } from '@root/common/base/PagePathHandler';
import matchChannel from '@root/common/utils/matchChannel';
import { buildAbsoluteRoute, buildQueryUrl } from '@root/router/utils/buildRoutePath';
import { Data as CategoryData } from '@root/modules/category/types/categories';
import { Context } from '@nuxt/types';

export { RedirectStatus as CategoryRedirectStatus };

interface CategoryPathContext {
  domain: string;
  fakeDomainEnabled: boolean;
  query: Context['route']['query'];
  route: Context['route'];
}

type CategoryDataDeconstructed = Pick<CategoryData, 'id' | 'slug' | 'channel'>;

/**
 * Create category path for redirecting to the correct channel
 */
export class CategoryPath extends PagePathHandler {
  private _categoryPathData: CategoryDataDeconstructed | null = null;

  constructor({ id, slug, channel }: CategoryDataDeconstructed, options?: { redirectConditions: Partial<RedirectConditions> }) {
    super({
      name: 'CategoryPath',
      redirectConditions: {
        channel: true,
        id: false,
        slug: false,
        ...options?.redirectConditions,
      },
    });

    this.initializeAndValidate({ id, slug, channel });
  }

  /**
   * Initialize and validate the category data
   * Check if the category data is valid and set the error if not
   */
  private initializeAndValidate({ id, slug, channel }: CategoryDataDeconstructed): void {
    // TODO: .? operator should be removed after the category data types are fixed
    const domain = channel?.domain;

    const isDataValid = Boolean(typeof id === 'number' && slug && channel && domain);

    this._isDataValid = isDataValid;
    this._categoryPathData = isDataValid ? { id, slug, channel } : null;

    if (!this._isDataValid) {
      this._error = new Error(`${this._name} initializeAndValidate: Invalid category data - id: ${id}, slug: ${slug}, channel: ${JSON.stringify(channel)}`);
    }
  }

  /**
   * Create new path for the category redirect or return null if no redirect is needed
   */
  public createPath({ domain, fakeDomainEnabled, query, route }: CategoryPathContext): string | null {
    // Handle invalid data
    if (!this.isRedirectDataValid(this._categoryPathData)) {
      return null;
    }

    const { id, channel } = this._categoryPathData;
    // TODO: as string should be removed after the category data types are fixed
    const slug = this._categoryPathData.slug as string;
    const categoryDomain = channel.domain;
    const pathOptions = { type: 'category', id, slug, channel };

    const sameChannel = matchChannel(domain, categoryDomain);

    // Check redirect conditions
    if (!this.shouldRedirect({ id, sameChannel, route })) {
      return null;
    }

    // Build the URL for redirect
    const url = buildAbsoluteRoute({ pathOptions, domain, fakeDomainEnabled });
    const queryUrl = buildQueryUrl(url, { query, route }) || null;

    // Handle invalid URL data
    // Skip redirect if the current URL is the same as the new URL - prevent infinite loop
    if (!this.isRedirectUrlValid(queryUrl, { domain, route })) {
      return null;
    }

    // Create the new path for redirection
    return this.handleRedirectPath(queryUrl);
  }
}
