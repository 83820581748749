import { SentryError, type ErrorContext, type ErrorTags, type ServiceErrorTags } from '@root/common/base/SentryError';
import { CategoryFetchState } from '@root/modules/category/domain';

import { CategoryFetchErrorReason } from './CategoryFetchReason.error';

interface CategoryFetchErrorContext {
  cause: {
    reason: CategoryFetchErrorReason.NoData;
  };
  data: {
    category: CategoryFetchState['category'];
    refetchType: CategoryFetchState['refetchType'];
  };
}

export class CategoryFetchDataError extends SentryError<CategoryFetchErrorContext, ServiceErrorTags> {
  constructor(message: string, { state }: { state: CategoryFetchState }) {
    super(message);
    this.clientMessage = 'category.error.not_found';

    const sentryContext: ErrorContext<CategoryFetchErrorContext> = {
      cause: {
        reason: CategoryFetchErrorReason.NoData,
      },
      data: {
        category: state.category,
        refetchType: state.refetchType,
      },
    };

    const sentryTags: ErrorTags<ServiceErrorTags> = {
      apiType: 'content',
      responseCode: 500,
    };

    this.setInitialContexts(sentryContext);
    this.setInitialTags(sentryTags);
  }
}
