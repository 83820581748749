import { FetchHandler } from '@root/common/domain';

import { CategoryWithHeadlinesService, CategoryWithFeedService } from '@root/modules/category/services';
import { CategoryFetchDataError } from '@root/modules/category/errors';
import { FeedAdapter } from '@root/modules/feed/utils';

import { isValidSignedInt32 } from '@root/common/utils/helpers';

import { CategoryFetchState } from './CategoryFetchState.domain';
import { CategoryFetchContext } from './CategoryFetchContext.domain';

import { FetchStatus, type FetchResponse } from '@root/common/types/domain';
import type { Data as Category, HeadlinesData as Headlines } from '@root/modules/category/types/categories';
import type { Feed } from '@root/modules/feed/types';

type CategoryWithHeadlinesData = {
  category: Category;
  headlines: Headlines;
};

type CategoryWithFeedData = {
  category: Category;
  feed: Feed;
};

type CategoryHeadlinesAndFeedData = {
  category: Category;
  headlines?: Headlines;
  feed?: Feed;
};

type CategoryFetchResponse = FetchResponse<CategoryHeadlinesAndFeedData, CategoryFetchDataError>;

export class CategoryFetchHandler extends FetchHandler<CategoryFetchState, CategoryFetchContext> {
  constructor(FeedFetchState: CategoryFetchState, FeedFetchContext: CategoryFetchContext) {
    super(FeedFetchState, FeedFetchContext);
  }

  /**
   * Validate input before fetching data with service and return error if input is invalid
   */
  private handleInputError(id: number): CategoryFetchDataError | null {
    let fetchInputError: null | CategoryFetchDataError = null;

    if (Number.isNaN(Number(id)) || !isValidSignedInt32(Number(id))) {
      this.state.fetchStatus = FetchStatus.Error;

      fetchInputError = new CategoryFetchDataError('Category fetch error - invalid input', {
        state: this.state,
      });

      fetchInputError.tags.responseCode = 400;
      fetchInputError.contexts.data.refetchType = this.state.refetchType;
    }

    return fetchInputError;
  }

  /**
   * Fetch category with headlines if page builder is not enabled for the category (default)
   */
  private async fetchCategoryWithHeadlines(categoryService: CategoryWithHeadlinesService): FetchResponse<CategoryWithHeadlinesData, CategoryFetchDataError> {
    // Handle data fetching with service
    const [serviceResponse, serviceError] = await categoryService.fetch(this.state, this.context);

    // Handle invalid response errors
    const noCategories = !serviceResponse?.categories?.data?.length;

    if (serviceError || noCategories) {
      this.state.fetchStatus = FetchStatus.Error;

      const fetchDataError = new CategoryFetchDataError('Category fetch error - invalid headlines data', {
        state: this.state,
      });

      if (serviceError) {
        fetchDataError.tags.responseCode = serviceError?.statusCode ?? fetchDataError.tags.responseCode;
      } else if (noCategories) {
        fetchDataError.tags.responseCode = 404;
      }

      fetchDataError.contexts.data.refetchType = this.state.refetchType;

      this.state.fetchStatus = FetchStatus.Error;
      return [null, fetchDataError];
    }

    const categoryResponse = serviceResponse.categories.data[0];
    const headlinesResponse = serviceResponse.headlines;

    // Handle successful  data fetch
    this.state.fetchStatus = FetchStatus.Success;

    return [{ category: categoryResponse, headlines: headlinesResponse }, null];
  }

  /**
   * Fetch category with feed if page builder is enabled for the category
   */
  private async fetchCategoryWithFeed(categoryService: CategoryWithFeedService): FetchResponse<CategoryWithFeedData, CategoryFetchDataError> {
    // Handle data fetching with service
    const [serviceResponse, serviceError] = await categoryService.fetch(this.state, this.context);

    // Handle invalid response errors
    if (serviceError || !serviceResponse?.feed?.items?.[0]?.sections?.items?.length) {
      this.state.fetchStatus = FetchStatus.Error;

      const fetchDataError = new CategoryFetchDataError('Feed fetch error - invalid feed data', {
        state: this.state,
      });

      if (serviceError) {
        fetchDataError.tags.responseCode = serviceError?.statusCode ?? fetchDataError.tags.responseCode;
      } else if (!serviceResponse?.feed?.items?.[0]?.sections?.items?.length) {
        fetchDataError.tags.responseCode = 404;
      }

      fetchDataError.contexts.data.refetchType = this.state.refetchType;

      this.state.fetchStatus = FetchStatus.Error;
      return [null, fetchDataError];
    }

    const feedResponse = serviceResponse.feed.items[0].sections.items;
    const categoryResponse = serviceResponse.categories.data[0];

    // Adapt data to make sure it is in the correct format
    const feedAdapter = new FeedAdapter(feedResponse);
    const adaptedFeed = feedAdapter.adapt();

    // Handle successful data fetch
    this.state.fetchStatus = FetchStatus.Success;

    return [{ category: categoryResponse, feed: adaptedFeed }, null];
  }

  public async handleFetch(categoryService: CategoryWithHeadlinesService | CategoryWithFeedService): CategoryFetchResponse {
    const inputError = this.handleInputError(Number(this.context.route.params.id));

    // Handle invalid input error
    if (inputError) {
      return [null, inputError];
    }

    let response!: Awaited<CategoryFetchResponse>;

    // Fetch headlines
    if (categoryService instanceof CategoryWithHeadlinesService) {
      response = await this.fetchCategoryWithHeadlines(categoryService);
    }

    // Fetch feed
    if (categoryService instanceof CategoryWithFeedService) {
      response = await this.fetchCategoryWithFeed(categoryService);
    }

    return response;
  }
}
